<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item class="title">{{ $t("adnavPage.supplierManagement") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="top-btn">
      <el-form class="flex-row" ref="form" :model="form" style="width: 800px">
        <el-form-item class="flex-row" :label="$t('supplierNameColumn')">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-button
          class="btn-black"
          style="margin: 0 0 22px 20px"
          @click="initData"
        >{{ $t("search") }}</el-button>
      </el-form>
      <el-button
        class="btn-black"
        style="height: 40px; margin-right: 10px"
        @click="createdSupplier"
      >{{ $t("createdSupplier") }}</el-button>
    </div>
    <el-table :data="tableData">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column prop="supplierName" :label="$t('supplierName')" align="center"></el-table-column>
      <el-table-column
        prop="address"
        :label="$t('businessID')"
        width="360"
        :show-overflow-tooltip="true"
        align="center"
      ></el-table-column>
      <el-table-column prop="createdAt" :label="$t('createDate')" width="160" align="center">
        <template v-slot="scope">{{ scope.row.createdAt | timeStampToDate }}</template>
      </el-table-column>
      <el-table-column prop="interestRate" :label="$t('businessStatus')" width="100" align="center">
        <template v-slot="scope">
          {{
          scope.row.status | authStatus
          }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('operation')" width="200" align="center">
        <template v-slot="scope">
          <el-button
            size="small"
            class="btn-black"
            :disabled="scope.row.status.toString() === authAll.UNKNOWN.valueNew || scope.row.status.toString() === authAll.UNKNOWNTOO.valueNew"
            @click="
              $router.push({
                path: `/financing-manage/supply-detail?id=${scope.row.spUserId}`,
              })
            "
          >{{ $t("see") }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('createdSupplier')"
      :visible.sync="dialogForm"
      width="35%"
      style="min-width: 350px;"
      @close="close()"
    >
      <div v-if="!supplierNumber" style="padding: 0 40px;">
        <el-form :model="ruleForm" ref="ruleForm" label-position="top">
          <el-form-item
            :label="$t('tipsMessage.nameOfTheSupplier')"
            prop="companyName"
            style="width: 100%;"
          >
            <el-input v-model="ruleForm.supplierName" clearable @input="changeInput"></el-input>
          </el-form-item>
          <el-form-item :label="$t('tipsMessage.emailAddress')" prop="email" style="width: 100%;">
            <el-input v-model="ruleForm.email" clearable @input="changeInput"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="supplierNumber">
        <div style="text-align: center; color: #000000">{{ ruleForm.supplierName }}</div>
        <div style="text-align: center; color: #000000; padding: 20px 0">
          <span
            style="font-size: 24px; font-weight: 800; letter-spacing: 10px"
            id="supplier-number"
          >{{ supplierNumber }}</span>
          <span
            style="font-size: 14px; padding-left: 20px; color: #81d8d0; cursor: pointer; position: absolute; line-height: 35px;"
            class="copyBtn"
            data-clipboard-target="#supplier-number"
            @click="copyClick()"
          >{{ $t('copyText') }}</span>
        </div>
        <div v-if="language === 'zh-cn'">{{ $t('tipsMessage.invitationCodeDesc') }}</div>
        <div v-else style="text-align: center">
          {{ $t('tipsMessage.invitationCodeDesc').split(',')[0] + ', ' }}
          <br />
          {{ $t('tipsMessage.invitationCodeDesc').split(',')[1] }}
        </div>
      </div>
      <div slot="footer">
        <div v-if="!supplierNumber">
          <el-button
            :disabled="isCreated"
            class="btn-black"
            @click="affirmCreated"
          >{{ $t('invitationText') }}</el-button>
          <el-button @click="dialogForm = false">{{ $t('cancelText') }}</el-button>
        </div>
        <div v-if="supplierNumber" style="display: flex; justify-content: center">
          <el-button @click="close()">{{ $t('btnI18n.Close') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import AdPagination from "@/components/ad-pagination";
export default {
  components: {
    AdPagination
  },
  data() {
    return {
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      form: {
        companyName: ""
      },
      tableData: [],
      dialogVisible: false,
      companyItem: {},
      dialogForm: false,
      ruleForm: {
        supplierName: '',
        email: '',
      },
      supplierNumber: '',
      tableLoading: false,
      tableEmpty: this.$t('loading'),
      isCreated: true,
      authAll: this.$enums.AUTHSTATUS
    };
  },
  mounted() {
    this.tableLoading = true;
    this.initData();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    initData() {
      let _this = this;
      this.$axios.get('/v1/brand-owner/suppliers', { params: { name: this.form.companyName, page: this.tablePageCP, pageSize: this.tablePagePS } }).then(result => {
        _this.tableLoading = false;
        if (!result.code) {
          _this.tableData = result.data.rows;
          _this.tableTotal = result.data.count || 0;
        }
      }).catch(() => {
        _this.tableLoading = false;
      });
      this.tableEmpty = this.$t('tableEmpty');
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 创建供应商
    createdSupplier() {
      this.dialogForm = true;
    },
    changeInput() {
      this.isCreated = this.ruleForm.supplierName === "" || this.ruleForm.email === "";
    },
    async affirmCreated() {
      if (!this.ruleForm.supplierName) {
        this.$message({
          message: this.$t(`formValidate.companyNameRequired`),
          type: 'warning'
        });
        return false;
      }
      if (!this.ruleForm.email) {
        this.$message({
          message: this.$t(`formValidate.emailRequired`),
          type: 'warning'
        });
        return false;
      }
      if (this.ruleForm.email && !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ig.test(this.ruleForm.email)) {
        this.$message({
          message: this.$t(`formValidate.emailError`),
          type: 'warning'
        });
        return false;
      }
      const res = await this.$axios.post('/v1/brand-owner/invite', this.ruleForm);
      if (!res.code) {
        this.supplierNumber = res.data.code;
      };
    },
    copyClick() {
      let clipboard = new Clipboard('.copyBtn');
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功',
          type: 'success'
        });
        console.log('11');
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', (e) => {
        this.$message({
          message: '复制失败',
          type: 'error'
        });
        console.log('22');
        // 释放内存
        clipboard.destroy();
      });

    },
    close() {
      this.supplierNumber = '';
      this.ruleForm.supplierName = '';
      this.dialogForm = false;
    }
  },
  computed: {
    supplierName() {
      return this.ruleForm.supplierName;
    },
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.pages {
  position: absolute;
  bottom: 10px;
  right: 0;
}
.top-btn {
  display: flex;
  justify-content: space-between;
}
</style>
